.newsletter {
  background: #17bf9e;

  padding: 60px;
  border-radius: 15px;
}

.newsletter h2 {
  color: #fff;
}
.subscribe input {
  border: none;
  outline: none;
  background: #fff;
  border-radius: 7px;
  height: 30px;
}

.subscribe {
  padding: 7px 10px;
  width: 45%;
  margin: auto;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subscribe button {
  border: 1px solid white;
}
input::placeholder {
  position: relative;
  left: 10px;
}

@media screen and (max-width: 800px) {
 .subscribe  {
   display: block;
  }
  .subscribe button {
    border: 1px solid white;
    margin-top: 10px;
  }
}
