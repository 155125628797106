.hero__section {
  background-image: url('../../assests/images/hero-image.jpg');
  background-size: cover;
  background-position: center;
  margin-top: 40px;
}

.hero__content h2 {
  font-weight: 600;
  line-height: 55px;
  margin-top: 30px;
}

.hero__content {
  padding-top: 80px;
}
.search input {
  border: none;
  outline: none;
  padding: 7px 10%;
}

.search {
  width: 60%;
  border: 1px solid #17bf9d44;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  margin-top: 20px;
}


@media screen and (max-width: 800px) {
  .search {
      margin-bottom: 20px;
    }
  }