@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Andada+Pro:wght@400;500;600;700;800&display=swap");

/* =========== base style ========= */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

section,
footer {
  padding-top: 70px;
  padding-bottom: 70px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 2rem;
  line-height: 50px;
  font-family: "Poppins", sans-serif;
  color: #0a2b1e;
  font-weight: 600;
}
h1 {
  color: white;
}

p {
  color: #596b65;
  font-size: 1rem;
  line-height: 35px;
  font-family: "Andada Pro", serif;
  font-weight: 400;
}

button.btn {
  background: #17bf9e;
  color: #fff;
  padding: 7px 25px;
  border-radius: 15px;
}

i {
  color: #17bf9e;
}

.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

 .dropdown-content {
  /* display: none; */
  position: absolute;
  right: 0;
  background-color: white;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 5px;
  top: 30px;
  border-radius: 4px;
  line-height: 50px;
}

 .dropdown-content a {
  color: black;
  text-decoration: none;
  display: block;
  margin-left: 0;
  padding-left: 10px;
}

 .dropdown-content a:hover {
  background-color:#3e8e41;
  color: white;
}

/* nav .nav_middle .dropdown:hover .dropdown-content {
  display: block;
} */

 .dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
.li_dropdown {
  position: relative;
  left: 20px;
}
.btncall {
  position:fixed; 
  right: 40px;
  top:590px;
  line-height: 30px;
  z-index: 20;
}
.btnwp {
  position:fixed; 
  right: 40px;
  top:520px;
  line-height: 30px;
  z-index: 20;
}

button {
  border-radius: 5px;
  padding: 10px 30px;
  background: #F1592A;
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
}








@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
  background-color: #fff;
}
.container {
  max-width: 80%;
  margin: auto;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container.flex.mtop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
a {
  text-decoration: none;
  color: white;
  text-transform: capitalize;
}
ul {
  list-style-type: none;
}
button {
  border-radius: 5px;
  padding: 10px 30px;
  background: #17BF9E;
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
}
button i {
  margin-right: 5px;
}
.heading {
  /* text-align: center; */
  width: 50%;
  /* margin: auto; */
}
.heading h1 {
  color: #2d3954;
  font-weight: 600;
  /* text-transform: capitalize; */
  font-size: 35px;
}
.heading p {
  color: #72809d;
}
textarea,
input {
  border: none;
  outline: none;
  background: none;
}
.background {
  padding: 80px 0;
  position: relative;
  background: #f7f9fc;
}

.grid5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
}
label {
  color: #2d3954;
  font-size: 14px;
}
.mtop {
  margin-top: 50px;
}
h4 {
  font-weight: 500;
  margin-top: 10px;
}
.shadow {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
}
.padding {
  padding: 80px 0;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.btn2 {
  border-radius: 15px;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.grid4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}
.btn3 {
  background: #ff6922;
  border-radius: 50px;
}
.btn4 {
  background-color: #000;
  color: #fff;
}
.btn5 {
  border-radius: 50px;
  padding: 20px 40px;
  color: #F1592A;
  font-size: 20px;
  font-weight: 400;
  border: 5px solid #27ae601f;
  background: #fff;
}
.back {
  height: 40vh;
  position: relative;
}
.back img {
  height: 40vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
}
.back::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  background: rgba(23, 191, 158, 0.629);
  z-index: -1;
}
.back .container {
  color: #fff;
  padding: 120px 0;
}
.back h1 {
  font-size: 40px;
  font-weight: 500;
}
.mb {
  margin-bottom: 80px;
}


@media screen and (max-width: 900px) {
  /* .grid4,
  .grid3, */
  .grid5 {
    grid-template-columns: repeat(3, 2fr);
  }
}

@media screen and (max-width: 800px) {
  .grid4,
  .grid3,
  .grid5 {
    grid-template-columns: repeat(2, 1fr);
  }
  .heading {
    width: 100%;
  }
  .container {
    max-width: 90%;
  }
  .back::after,
  .back img,
  .back {
    height: 40vh;
  }
  .btn5 {
    border-radius: 30px;
    padding: 20px 10px;
    color: #F1592A;
    font-size: 20px;
    font-weight: 400;
    border: 5px solid #27ae601f;
    background: #fff;
  }
  .container.flex.mtop {
    display: block;
    justify-content: space-between;
    align-items: center;
  }
  .btncall {
    position:fixed; 
    right: 40px;
    top:590px;
    line-height: 30px;
  }
  .btnwp {
    position:fixed; 
    right: 40px;
    top:520px;
    line-height: 30px;
  }
  .dropdown-content { 
    overflow: scroll;
    height: 400px;
  }
  
}




